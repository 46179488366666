/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:d8ba7d4c-589a-442b-b75f-35331fc69ba9",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_lydfNjpHj",
    "aws_user_pools_web_client_id": "4h4tjqs5e7saks9ingohn1ugim",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "PHONE_NUMBER"
    ],
    "aws_appsync_graphqlEndpoint": "https://i54ukpjkszf77c3aqtffegdqxq.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AWS_IAM",
    "aws_appsync_apiKey": "da2-oqj5qbj2trb7netdnt6kqy65iq",
    "aws_user_files_s3_bucket": "personalwebsitemateofabianoryx141248-staging",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
